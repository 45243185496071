<template>
  <div id="share" class="bottom-[100px]" :class="{
    'bottom-[130px]': isNavigatorStandalone()
  }" v-on-click-outside="onClose">
    <Transition name="slide-fade">
        <div v-if="active" id="dropdown" class="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
            <ul class="py-1 text-sm text-gray-700" aria-labelledby="dropdownDefaultButton">
              <li>
                <a href="#" class="block px-3 py-2 hover:bg-gray-100" @click.prevent="onScreenshotClick($event)"><Icon icon="ion:camera" class="inline size-5 mr-2" />Screenshot</a>
              </li>
              <li>
                <a href="#" class="block px-3 py-2 hover:bg-gray-100" @click.prevent="onRecordGifClick($event)"><Icon icon="teenyicons:gif-outline" class="inline size-5 mr-2" />Record GIF</a>
              </li>
              <li>
                <a href="#" class="block px-3 py-2 hover:bg-gray-100" @click.prevent="onRecordVideoClick($event)"><Icon icon="fluent:video-28-filled" class="inline size-5 mr-2" />Record Video</a>
              </li>
              <li>
                <a href="#" class="block px-3 py-2 hover:bg-gray-100" @click.prevent="onShareClick($event)"><Icon icon="ion:share-outline" class="inline size-5 mr-2" />Share Map</a>
              </li>
            </ul>
        </div>
    </Transition>
    <button type="button" class="fixed bottom-14 right-4 w-12 h-12 rounded-full transition-all duration-300 group focus:outline-none"
        :class="{
          'bg-[#F6B91A] hover:bg-[#17274F] hover:text-[#F6B91A]': ! recording,
          'bg-red-700 hover:bg-red-800 text-white': recording,
          'bottom-24': isNavigatorStandalone()
        }"
        @click.prevent="onButtonClick($event)">

      <Icon v-if="!active && ! recording" icon="ion:share-outline" class="inline size-8" />
      <template v-else>
        <Icon v-if="recording" icon="bi:stop-fill" class="inline size-8" />
        <Icon v-else icon="clarity:window-close-line" class="inline size-8" />
      </template>
    </button>

  </div>
</template>

<script setup>
import { vOnClickOutside } from '@vueuse/components'
</script>

<script>
import { useRecordingStore } from '@/stores/recording'
import { Icon } from '@iconify/vue'
import { isNavigatorStandalone } from '@/logic/Extra/helpers'

import modals from '@/logic/Modals/Helper'

export default {
  name: 'ShareButton',
  components: {
    Icon
  },
  computed: {
    recordingStore: () => useRecordingStore(),
    recording() {
      return this.recordingStore.recording
    }
  },
  data() {
    return {
      active: false,
      recordingMethod: ''
    };
  },
  methods: {
    onButtonClick(e) {
      if(this.recordingMethod === 'GIF') {
        window.gif.stop()
        this.recordingStore.stop()
        this.recordingMethod = '';
        this.active = false;
        return;
      }
      else if(this.recordingMethod === 'MP4') {
        setTimeout(() => window.mp4.stop(), 1000)
        this.recordingStore.stop()
        this.recordingMethod = '';
        this.active = false;
        return;
      }
      this.active = !this.active;
    },

    onClose() {
      this.active = false;
    },

    // Screenshot, etc. related
    onScreenshotClick(e) {
      this.active = false;

      window.screenshot.take()
    },

    onRecordGifClick(e) {
      this.active = false;

      window.gif.record()
      this.recordingStore.start()

      this.recordingMethod = 'GIF';
    },

    onRecordVideoClick(e) {
      this.active = false;

      window.mp4.record()
      this.recordingStore.start()

      this.recordingMethod = 'MP4';
    },

    onShareClick(e) {
      this.active = false;

      modals.share();
    },
  }
}
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from {
  transform: translateX(20px);
  opacity: 0;
}

.slide-fade-leave-to {
  display: none;
}

#share {
  z-index: 4;
  position: fixed;
  right: 10px;
}

#dropdown {
  position: absolute;
  top: -160px;
  right: 0px;
}
</style>
