<script setup>
import { useAppStore } from '@/stores/app'
</script>

<template>
  <div id="map"></div>
</template>

<script>
import mapboxgl from 'mapbox-gl'

import MapboxPopups from '@/tools/mapbox-popups'
import Radar from '@/logic/Radar'
import Warnings from '@/logic/Warnings'
import MesoscaleDiscussions from '@/logic/MesoscaleDiscussions'
import Outlooks from '@/logic/Outlooks'
import Tropical from '@/logic/Tropical'
import Robot from '@/logic/Robot'
import Mp4Recorder from '@/logic/Extra/Mp4Recorder'
import GifRecorder from '@/logic/Extra/GifRecorder'
import Screenshot from '@/logic/Extra/Screenshot'

import { applyIsInteracting } from '@/tools/mapbox-map'

const mapboxglAccessToken =
  'pk.eyJ1Ijoid2VhdGhlcndpc2UtYXBwIiwiYSI6ImNseW96cjFxdTBtcmwydnM1dmt2cjhjczAifQ.NCw6Nqji8wed41RJEZ4SVQ'

export default {
  name: 'MapboxMap',
  data() {
    return {
      options: {
        accessToken: mapboxglAccessToken,
        container: 'map',
        style: 'mapbox://styles/mapbox/navigation-night-v1',
        bounds: [
          [ -130.689866, 15.458159 ],
          [ -62.009844, 53.686814 ]
        ],
        hash: 'map',
        projection: 'mercator',
        preserveDrawingBuffer: true,
        fadeDuration: 0,
        crossSourceCollisions: false,
        transformRequest: (url, type) => {
          if (type === 'Image') {
            return {
              url: url,
              headers: {
                Origin: window.location.origin
              }
            }
          }
        }
      }
    }
  },
  computed: {
    appStore: () => useAppStore(),
  },
  mounted() {
    // create the map after the component is mounted
    this.createMap()
  },
  methods: {
    createMap() {
      // instantiate map.  this method runs once after the vue component is mounted to the dom
      window.map = this.map = new mapboxgl.Map(this.options)

      // disable map rotation using right click + drag
      this.map.dragRotate.disable()

      // disable map rotation using touch rotation gesture
      this.map.touchZoomRotate.disableRotation()

      // disable map rotation via keyboard
      this.map.keyboard.disableRotation()

      // disable map tilting using two-finger gesture
      this.map.touchPitch.disable()

      applyIsInteracting(this.map);

      this.map.on('load', () => {
        this.map.resize();

        // TODO
        // This can be a premium feature
        // Remove traffic layer
        this.map.getStyle().layers.filter(l => l.id.startsWith('traffic')).forEach(l => map.setLayoutProperty(l.id, 'visibility', 'none'))

        // 0.01 is the default
        this.map.scrollZoom.setZoomRate(0.01 * 2.2)

        // 0.002222 is the default
        this.map.scrollZoom.setWheelZoomRate(0.002222 * 2.2)

        // Add night fog
        this.map.setFog({
          'horizon-blend': 0.02,
          'color': '#242B4B',
          'high-color': '#161B36',
          'space-color': '#0B1026',
          'star-intensity': 0.5
        })

        map.popups = new MapboxPopups(this.map);

        map.radar = new Radar(this.map);

        map.outlooks = new Outlooks(this.map);

        map.warnings = new Warnings(this.map);

        map.mesoscaleDiscussions = new MesoscaleDiscussions(this.map);

        map.tropical = new Tropical(this.map);

        // TODO
        // Remove global references
        window.mp4 = new Mp4Recorder(this.map)
        window.gif = new GifRecorder(this.map)
        window.screenshot = new Screenshot(this.map);

        map.robot = new Robot(this.map);

        this.appStore.ready();
        this.appStore.showMode();
      })
    }
  }
}
</script>

<style scoped>
#map {
  height: 100%;
  width: 100%;
  margin: 0 auto;
}
</style>
