<script setup>
import { vOnClickOutside } from '@vueuse/components'
</script>

<template>
  <div class="absolute top-3 left-3 sm:left-1/2 sm:ml-[-218px] z-30">
    <div class="h-[50px] px-4 bg-ww-slate-100 rounded-lg flex select-none">
      <button @click.stop="toggleDropdown" class="flex items-center focus:outline-none hover:bg-gray-200 rounded-lg transition-colors duration-200 group my-2 px-2">
        <img src="../assets/weatherwise-logo.svg" class="h-3/4 max-w-[27px] object-contain flex-initial self-center" alt="WeatherWise Logo">
        <div class="font-[Outfit] text-lg items-center flex flex-1 ml-3 hidden sm:flex">
          <span class="text-[#17274F] font-bold drop-shadow-sm transition-colors duration-200">Weather</span><span class="text-[#F6B91A] font-bold drop-shadow-sm group-hover:text-[#F6B91A] transition-colors duration-200">Wise</span>
        </div>
        <Icon :icon="dropdownOpen ? 'gravity-ui:chevron-up-wide' : 'gravity-ui:chevron-down-wide'" class="self-center ml-2 transition-colors duration-200" />
      </button>

      <div style="border-right: 1px solid #CCC;" class="my-2 mx-4"></div>

      <ul class="flex flex-wrap text-sm font-medium text-center text-gray-700 sm:hidden" style="margin-top: 7px">
        <li>
          <a v-if="appStore.mode === 'RADAR'" @click.prevent="onMobileMenuItemClick($event)" href="#" class="inline-block px-4 py-2 font-bold text-white bg-blue-600 hover:bg-blue-800 rounded-lg active"><Icon icon="game-icons:radar-sweep" class="inline size-6" /><span class="font-bold ml-2">Radar</span><Icon :icon="menuOpen ? 'gravity-ui:chevron-up-wide' : 'gravity-ui:chevron-down-wide'" class="size-4 inline ml-3" /></a>
          <a v-if="appStore.mode === 'OUTLOOKS'" @click.prevent="onMobileMenuItemClick($event)" href="#" class="inline-block px-4 py-2 font-bold text-white bg-blue-600 hover:bg-blue-800 rounded-lg active"><Icon icon="arcticons:forecaster" class="inline size-6" /><span class="font-bold ml-2">Outlooks</span><Icon :icon="menuOpen ? 'gravity-ui:chevron-up-wide' : 'gravity-ui:chevron-down-wide'" class="size-4 inline ml-3" /></a>
        </li>
      </ul>

      <ul class="flex flex-wrap text-sm font-medium text-center text-gray-700 hidden sm:flex" style="margin-top: 7px">
        <li class="">
          <a href="#" @click.prevent="onModeClick($event, 'RADAR')" class="inline-block px-4 py-2 font-bold rounded-lg" :class="{
            'text-white bg-blue-600 hover:bg-blue-700 active': appStore.mode === 'RADAR',
            'hover:text-gray-950 hover:bg-gray-200': appStore.mode !== 'RADAR'
          }"><Icon icon="game-icons:radar-sweep" class="inline size-6" /><span class="font-bold ml-2">Radar</span></a>
        </li>
        <li class="ms-2">
          <a href="#" @click.prevent="onModeClick($event, 'OUTLOOKS')" class="inline-block px-4 py-2 font-bold rounded-lg" :class="{
            'text-white bg-blue-600 hover:bg-blue-700 active': appStore.mode === 'OUTLOOKS',
            'hover:text-gray-950 hover:bg-gray-200': appStore.mode !== 'OUTLOOKS'
          }"><Icon icon="arcticons:forecaster" class="inline mr-2 size-6" />Outlooks</a>
        </li>
      </ul>
    </div>

    <Transition name="slide-down">
      <div v-if="menuOpen" v-on-click-outside="onMenuOutsideClick" id="mobile-menu" class="absolute left-0 sm:left-1/2 sm:-translate-x-1/2 w-full z-30 select-none origin-top mt-[7px]">
        <div class="bg-white rounded-lg">
          <ul class="text-sm font-medium text-center text-gray-700 p-3">
            <li>
              <a href="#" @click.prevent="onModeClick($event, 'RADAR')" class="block px-4 py-2 font-bold rounded-lg relative" :class="{
                'text-white bg-blue-600 active': appStore.mode === 'RADAR',
                'hover:text-gray-950 hover:bg-gray-200': appStore.mode !== 'RADAR'
              }"><Icon icon="game-icons:radar-sweep" class="inline size-6 absolute left-3 top-1.5" />Radar</a>
            </li>
            <li class="mt-3">
              <a href="#" @click.prevent="onModeClick($event, 'OUTLOOKS')" class="block px-4 py-2 font-bold rounded-lg relative" :class="{
                'text-white bg-blue-600 active': appStore.mode === 'OUTLOOKS',
                'hover:text-gray-950 hover:bg-gray-200': appStore.mode !== 'OUTLOOKS'
              }"><Icon icon="arcticons:forecaster" class="inline mr-2 size-6 absolute left-3 top-1.5" />Outlooks</a>
            </li>
          </ul>
        </div>
      </div>
    </Transition>

    <Transition name="slide-down">
      <div v-if="dropdownOpen" v-on-click-outside="closeDropdown" class="absolute left-0 z-40 bg-white rounded-lg shadow-lg p-4 w-full origin-top select-none mt-[7px]">
        <!-- Add your dropdown menu items here -->
        <ul class="space-y-2">
          <!-- <li v-if="! inApp"><a href="#" class="block hover:bg-gray-200 px-4 py-2 rounded">Download our iOS App</a></li> -->
          <!-- <li v-if="! inApp"><a href="#" class="block hover:bg-gray-200 px-4 py-2 rounded">Download our Android App</a></li> -->
          <li><a href="https://x.com/WxWiseApp" class="block hover:bg-gray-200 px-4 py-2 rounded">Follow us on X</a></li>
          <li class="py-2">
            <hr class="border-t border-gray-200">
          </li>
          <li><a href="https://www.weatherwise.app" class="block hover:bg-gray-200 px-4 py-2 rounded">About</a></li>
          <li><a href="https://www.weatherwise.app/disclaimer.html" class="block hover:bg-gray-200 px-4 py-2 rounded">Disclaimer</a></li>
          <li><a href="https://www.weatherwise.app/privacy-policy.html" class="block hover:bg-gray-200 px-4 py-2 rounded">Privacy Policy</a></li>
          <li><a href="https://www.weatherwise.app/eula.html" class="block hover:bg-gray-200 px-4 py-2 rounded">Terms of Service</a></li>
        </ul>
      </div>
    </Transition>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue'
import { useAppStore } from '@/stores/app'
import { vOnClickOutside } from '@vueuse/components'

export default {
  name: 'AppLogo',
  components: {
    Icon
  },
  directives: {
    'on-click-outside': vOnClickOutside,
  },
  data() {
    return {
      menuOpen: false,
      dropdownOpen: false
    }
  },
  computed: {
    appStore() {
      return useAppStore()
    },
    inApp() {
      return typeof window.WeatherWiseBridge !== 'undefined'
    }
  },
  methods: {
    onModeClick(e, mode) {
      this.menuOpen = false;

      this.appStore.setMode(mode);
    },
    onMobileMenuItemClick(e) {
      this.menuOpen = !this.menuOpen;
    },
    onMenuOutsideClick() {
      // Hack to deal with mobile toggle button not working...
      setTimeout(() => {
        this.menuOpen = false;
      }, 10);
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    closeDropdown(event) {
      // Check if the click target is not the button
      if (!event.target.closest('button')) {
        this.dropdownOpen = false;
      }
    }
  }
}
</script>

<style scoped>
.slide-down-enter-active,
.slide-down-leave-active {
  transition: all 0.3s ease-out;
}

.slide-down-enter-from,
.slide-down-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

.slide-down-enter-to,
.slide-down-leave-from {
  transform: translateY(0);
  opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>