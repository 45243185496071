import Rollbar from 'rollbar'
import config from './rollbar.config'

const rollbar = new Rollbar(config)

export default {
  install(app) {
    app.config.errorHandler = (error, vm, info) => {
      console.error(error)

      const errString = error.toString();

      // Ignore this error from the FB bot: https://github.com/mapbox/mapbox-gl-js/issues/9488#issuecomment-610786721
      if(window.location.href.includes('fbclid')) {
        return;
      }

      if(import.meta.env.PROD) {
        rollbar.error(error, { vueComponent: vm, info })
      }
    };

    app.config.warnHandler = (warning, vm, info) => {
      console.warn(warning)

      if(import.meta.env.PROD) {
        rollbar.warning(warning, { vueComponent: vm, info })
      }
    };

    app.provide('rollbar', rollbar);
  }
}
