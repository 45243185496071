<template>
  <div v-if="radarTowersStore.anyActive"
       class="drawer-container fixed bottom-0 left-0 w-full transition-all duration-300 ease-in-out z-50 bg-white rounded-t-2xl shadow-lg flex flex-col text-black sm:w-[375px] md:w-[425px] sm:ml-2"
       :class="{
        'drawer-open': isOpen,
        'drawer-closed': !isOpen,
        'drawer-fullscreen': isNavigatorStandalone()
       }">
    <div @click="toggleDraw($event)" ref="drawTab" class="flex items-center justify-center cursor-pointer rounded-t-2xl px-4" :class="{
      'py-2': isOpen,
      'h-full': ! isOpen,
      'pb-4': (! isOpen) && isNavigatorStandalone()
    }">
      <div class="w-6/12 text-left">
        <div class="truncate"><span class="font-bold" v-text="radarTowersStore.activeTower.properties.code"></span><span class="mx-1">&bull;</span><span class="font-bold" v-text="productGroup.name"></span></div>
        <div class="text-xs">
          <template v-if="radarTowersStore.scanDatetime === null">Loading...</template>
          <template v-else>
            <span class="">Scan At: </span>
            <span v-text="radarTowersStore.scanLocalTime" :title="`UTC: ${radarTowersStore.scanDatetime}`" class="font-bold" :class="{
              'bg-red-100 text-red-800 px-2.5 py-0.5 rounded-full': !radarTowersStore.isPlaying && radarTowersStore.scanIsOld && !radarTowersStore.hasBufferedScans
            }"></span>
            <span v-if="!radarTowersStore.isPlaying && radarTowersStore.scanIsNew && !radarTowersStore.hasBufferedScans" class="font-black text-red-800 live-text ml-2">Live</span>
          </template>
        </div>
      </div>
      <Icon :icon="isOpen ? 'gravity-ui:chevron-down-wide' : 'gravity-ui:chevron-up-wide'" class="inline" />
      <div class="w-6/12 text-right" ref="controls">
        <!-- <button @click.prevent="onSliceClick($event)" type="button" class="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-2.5 py-2 pt-1.5 mr-2"><Icon icon="uil:line-alt" class="inline size-4" /></button>
        <button @click.prevent="onDataPickerClick($event)" type="button" class="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-2.5 py-2 pt-1.5 mr-2"><Icon icon="zondicons:target" class="inline size-4" /></button> -->
        <button @click.prevent="onPlayClick($event)" type="button" class="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-2.5 py-2 pt-1.5" :disabled='isPlaybackBuffering'><Icon :icon="playButtonIcon" class="inline size-4" /><span v-show="!radarTowersStore.isPlaying" class="font-bold ml-2">Play</span><span v-show="radarTowersStore.isPlaying" class="font-bold ml-2">Pause</span></button>
        <button v-show="radarTowersStore.hasBufferedScans" @click.prevent="onStopClick($event)" type="button" class="ml-2 text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-2.5 py-2 pt-1.5" :disabled='isPlaybackBuffering'><Icon icon="ph:stop-fill" class="inline size-4" /></button>
      </div>
    </div>
    <div v-show="isOpen" class="flex-grow flex flex-col border-t border-gray-200 overflow-y-auto">
      <div class="flex justify-start items-start m-3 select-none">
        <div class="w-7/12 text-sm">
          <template v-if="radarTowersStore.scanDatetime === null">Loading...</template>
          <template v-else>
            <div class="text-xs sm:text-sm">
              <span>Scan At: </span>
              <span v-text="radarTowersStore.scanLocalDatetime" :title="`UTC: ${radarTowersStore.scanDatetime}`" class="font-bold" :class="{
                'bg-red-100 text-red-800 px-2.5 py-0.5 rounded-full': !radarTowersStore.isPlaying && radarTowersStore.scanIsOld && !radarTowersStore.hasBufferedScans
              }"></span>
              <a href="#" class="ml-2 inline" @click.prevent="showRadarProductHelp($event)"><Icon icon="carbon:help" class="size-4 inline" /></a>
            </div>
            <div v-if="radarTowersStore.scanVcp !== null" class="text-xs sm:text-sm mt-1">
              <span>VCP: </span>
              <span class="font-bold">{{ radarTowersStore.scanVcp }} <span v-if="getVCPName(radarTowersStore.scanVcp)" class="font-bold">({{ getVCPName(radarTowersStore.scanVcp) }})</span></span>
              <a href="#" class="ml-2 inline" @click.prevent="showExtraHelp('VCP')"><Icon icon="carbon:help" class="size-4 inline" /></a>
            </div>
          </template>
        </div>
        <div class="text-right relative w-5/12">
          <RadarTilts :product-group="productGroup" />
        </div>
      </div>
      <div>
        <div v-for="row in productsGrid" :key="row" class="flex flex-nowrap space-x-4">
          <template v-for="col in row" :key="col">
            <div class="flex-1 min-w-0 p-2 text-center select-none" @click="onProductClick($event, col)">
              <div style="height: 60px; width: 60px;" class="ml-auto mr-auto rounded-lg overflow-hidden cursor-pointer" :class="{
                'border-sky-400 border border-4 rounded-lg': col.tilts.map(t => t.product).includes(radarTowersStore.activeProductCode)
              }">
                <img :src="getImageUrl(col.id)" :alt="col.name">
              </div>
              <div v-text="col.name" class="text-sm mt-1"></div>
            </div>
          </template>
        </div>
      </div>
      <div class="p-5" v-if="false">
        <hr class="mb-5">
        <div class="grid grid-cols-2 gap-4">
          <div>
            <label class="inline-flex items-center cursor-pointer w-full">
              <input type="checkbox" value="" class="sr-only peer" checked>
              <div class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
              <span class="ms-3 text-sm font-bold">Storm Tracks</span>
              <a href="#" class="ml-auto" @click.prevent="showExtraHelp()"><Icon icon="carbon:help" class="size-5" /></a>
            </label>
          </div>
          <div>
            <label class="inline-flex items-center cursor-pointer w-full">
              <input type="checkbox" value="" class="sr-only peer" checked>
              <div class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
              <span class="ms-3 text-sm font-bold">Lightning</span>
              <a href="#" class="ml-auto" @click.prevent="showExtraHelp()"><Icon icon="carbon:help" class="size-5" /></a>
            </label>
          </div>
          <div>
            <label class="inline-flex items-center cursor-pointer w-full">
              <input type="checkbox" value="" class="sr-only peer" checked>
              <div class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
              <span class="ms-3 text-sm font-bold">Melting Layer</span>
              <a href="#" class="ml-auto" @click.prevent="showExtraHelp()"><Icon icon="carbon:help" class="size-5" /></a>
            </label>
          </div>
          <div>
            <select class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg inline p-1">
              <option value="10">10 scans</option>
              <option value="30">30 scans</option>
              <option value="50">50 scans</option>
              <option value="100">100 scans</option>
            </select>
            <a href="#" class="inline float-right" @click.prevent="showExtraHelp()"><Icon icon="carbon:help" class="inline size-5" /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance } from 'vue'
import { Icon } from '@iconify/vue'
import { useModal, useModalSlot } from 'vue-final-modal'
import { useSwipe } from '@vueuse/core'

import { useRadarTowersStore } from '@/stores/radar_towers'
import RadarTilts from './Draw/RadarTilts.vue'

import { isNavigatorStandalone } from '@/logic/Extra/helpers'

import SimpleModal from '@/logic/Modals/Templates/Simple.vue'
import RadarProductHelpModal from '@/logic//Radar/RadarProductHelp.vue'

import { getVCPName } from '@/tools/helpers'

export default {
  name: 'RadarDrawer',
  setup() {
    const drawTab = ref(null)
    const instance = getCurrentInstance()

    onMounted(() => {
      const { direction } = useSwipe(drawTab, {
        onSwipe(e) {
          if (direction.value === 'down' && instance.proxy.isOpen) {
            instance.proxy.toggleDraw(e)
          }

          if (direction.value === 'up' && ! instance.proxy.isOpen) {
            instance.proxy.toggleDraw(e)
          }

          e.stopPropagation()
        }
      })
    })

    return {
      drawTab,
      isNavigatorStandalone
    }
  },
  data() {
    return {
      isOpen: false,
      isPlaybackBuffering: false,
    };
  },
  components: {
    Icon,
    RadarTilts
  },
  computed: {
    radarTowersStore() {
      return useRadarTowersStore()
    },
    productsGrid() {
      return this.radarTowersStore.towerProducts.filter(item => item.tilts.length).reduce((result, item, index) => {
        const groupIndex = Math.floor(index / 3);
        (result[groupIndex] = result[groupIndex] || []).push(item);
        return result;
      }, [])
    },
    productGroup() {
      return this.radarTowersStore.activeProductGroup
    },
    playButtonIcon() {
      if(this.isPlaybackBuffering) return 'eos-icons:three-dots-loading';

      return !this.radarTowersStore.isPlaying ? 'ph:play-fill' : 'ph:pause-fill';
    }
  },
  watch: {
    'radarTowersStore.anyActive': {
      handler(newValue) {
        if (! newValue) {
          this.isOpen = false
        }
      }
    }
  },
  methods: {
    onGoToActiveRadar() {
      if (! this.radarTowersStore.activeTower) {
        return
      }

      const activeRadarCoordinates = this.radarTowersStore.activeTower.geometry.coordinates;

      // TODO
      // Don't use reference to window.
      window.map.flyTo({
          center: activeRadarCoordinates,
          zoom: 8,
          essential: true
      })
    },
    getImageUrl(name) {
      return new URL(`../assets/product-tiles/${name}.png`, import.meta.url).href
    },
    openDraw() {
      this.isOpen = true;
    },
    closeDraw() {
      this.isOpen = false;
    },
    toggleDraw(e) {
      if(this.$refs.controls.contains(e.target) && this.$refs.controls !== e.target) return false;

      if (!this.isOpen) {
        this.openDraw();
      } else {
        this.closeDraw();
      }
    },
    onProductClick(e, productGroup) {
      this.setActiveProductGroup(productGroup)
    },
    onSliceClick(e) {

    },
    onDataPickerClick(e) {

    },
    async onPlayClick(e) {
      if(this.radarTowersStore.isPlaying) {
        this.radarTowersStore.isPlaying = false;
        return;
      }

      this.isPlaybackBuffering = true;

      const towerId = this.radarTowersStore.activeTowerId;
      const product = this.radarTowersStore.activeProductCode;
      
      try {
        const limit = 15;
        await window.map.radar.loadHistory(towerId, product, limit);

        window.map.radar.playScans(towerId, product);
      } catch(e) {
        console.error('Failed to buffer radar scans', e);
      }

      this.isPlaybackBuffering = false
    },
    onStopClick(e) {
      window.map.radar.clearPlaybackState();

      window.map.radar.loadRadarData(this.radarTowersStore.activeTower, this.radarTowersStore.activeProductCode)
    },
    showRadarProductHelp(e) {
      const productGroup = this.productGroup;

      if(typeof productGroup.help !== 'string') {
        return alert('Unable to locate help information');
      }

      window.map.radar.openRadarProductHelpModal(productGroup);
    },
    setActiveProductGroup(productGroup) {
      this.productGroup = productGroup;

      // TODO
      const productCode = productGroup.tilts[0].product;

      if (this.radarTowersStore.activeProductCode === productCode) {
        return;
      }

      console.log(this.radarTowersStore.activeTowerId, productCode)

      const feature = window.map.radar.changeRadarProduct(this.radarTowersStore.activeTowerId, productCode)
    },
    showExtraHelp(product) {
      let title;
      let text;

      if(product === 'VCP') {
        title = 'Volume Coverage Pattern (VCP)'
        text = `To capture as much atmospheric data as possible, the WSR-88D uses a volumetric scanning strategy. This involves the radar antenna scanning not just near the ground but also at various elevation angles, allowing it to gather vertical data. This approach helps forecasters better assess the depth and intensity of storms, especially when storms are close to the radar site.

To understand how Volume Coverage Patterns (VCPs) work, it's essential to know the two main operating modes: clear air mode and precipitation mode. Clear air mode, as the name implies, is used during calm weather. In this mode, the radar scans fewer elevation angles at a slower pace, reducing wear on its components. When the radar detects precipitation while in clear air mode, it automatically switches to precipitation mode. In precipitation mode, the radar scans 14 or 15 elevation angles, depending on the VCP being used, at a faster rate.`;

        // More detailed information can be found <a href='https://www.noaa.gov/jetstream/vcp_max' target='_blank' rel="noreferrer" class='font-medium text-blue-600 hover:underline'>here</a> and <a href='https://en.wikipedia.org/wiki/NEXRAD#Scan_strategies' target='_blank' rel="noreferrer" class='font-medium text-blue-600 hover:underline'>here</a>.
      }
      else {
        return alert('Unable to locate help information');
      }

      const modal = useModal({
        defaultModelValue: true,
        component: SimpleModal,
        attrs: {
          title
        },
        slots: {
          default: useModalSlot({
            component: RadarProductHelpModal,
            attrs: {
              text,
              onClose() {
                modal.close()
              },
            }
          })
        },
      })
    },
    getVCPName
  }
}
</script>

<style scoped>
.drawer-container {
  max-height: 400px;
}

.drawer-closed {
  height: 3.3rem;
  transform: translateY(calc(100% - 3.3rem));
}

.drawer-fullscreen {
  height: 5rem;
  transform: translateY(calc(100% - 5rem));
}

.drawer-open {
  height: 50vh;
  transform: translateY(0);
}

.live-text {
  animation: text-pulsate 3s ease-out infinite;
}

@keyframes text-pulsate {
  0% { 
    opacity: 1.0;
  }
  20% { 
    opacity: 0.3;
  }
  30%, 100% { 
    opacity: 1.0;
  }
}
</style>
