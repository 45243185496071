import moment from 'moment'

export const wait = ms => new Promise(resolve => setTimeout(resolve, ms));

export const debounce = (func, timeout) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
};

export const findClosestDate = (dates, refDate) => {
    return dates.reduce((closest, current) => {
        let currentDate = moment.utc(current);
        
        if (closest === null) return current;
        
        return Math.abs(currentDate.diff(refDate)) < Math.abs(moment.utc(closest).diff(refDate)) ? current : closest;
    }, null);
}

export const degreesToCardinal = (degrees) => {
	// Normalize degrees to be within 0-360
	degrees = (degrees % 360 + 360) % 360;

	const directions = [
		'North', 'North-Northeast', 'Northeast', 'East-Northeast', 
		'East', 'East-Southeast', 'Southeast', 'South-Southeast', 
		'South', 'South-Southwest', 'Southwest', 'West-Southwest', 
		'West', 'West-Northwest', 'Northwest', 'North-Northwest'
	];

	const index = Math.round(degrees / 22.5) % 16;
	
	return directions[index];
}

export const getVCPName = (vcpNumber) => {
    const vcpNames = {
    	// NEXRAD
        12: "Clear Air Mode",
        31: "Clear Air Mode",
        32: "Clear Air Mode",
        35: "Precipitation Mode",
        112: "Precipitation Mode",
        121: "Precipitation Mode",
        212: "Precipitation Mode",
        215: "Precipitation Mode",
        // TDWR
        90: "Clear Air Mode",
        80: "Precipitation Mode"

    };
    
    // Retrieve the VCP name or "Unknown VCP" if not found
	return vcpNames[vcpNumber] || null;
};

export const tropicalStormWindSpeedToCategory = (windSpeedKnots) => {
    // Convert knots to miles per hour (1 knot = 1.15078 mph)
    const windSpeedMph = windSpeedKnots * 1.15078;

    // Determine the hurricane category based on the Saffir-Simpson scale
    if (windSpeedMph >= 157) {
        return 5;
    } else if (windSpeedMph >= 130 && windSpeedMph < 157) {
        return 4;
    } else if (windSpeedMph >= 111 && windSpeedMph < 130) {
        return 3;
    } else if (windSpeedMph >= 96 && windSpeedMph < 111) {
        return 2;
    } else if (windSpeedMph >= 74 && windSpeedMph < 96) {
        return 1;
    }

    return 0;
};

export const tropicalStormWindSpeedToColor = (windSpeedKnots) => {
    const windSpeedMph = windSpeedKnots * 1.15078;

    if(windSpeedMph >= 157) return '#A188FC';
    if(windSpeedMph >= 130) return '#FF738A';
    if(windSpeedMph >= 111) return '#FF9E59';
    if(windSpeedMph >= 96) return '#FFD98C';
    if(windSpeedMph >= 74) return '#FFFFD9';
    if(windSpeedMph >= 39) return '#4DFFFF';

    return '#6EC1EA';
};

export const tropicalStormOceanToName = (code) => {
    if(code === 'AT') return 'Atlantic';
    if(code === 'EP') return 'Eastern Pacific';
    if(code === 'CP') return 'Central Pacific';

    return 'Unknown';
};
