<script setup>
import { VueFinalModal } from 'vue-final-modal'
import { useTimeAgo } from '@vueuse/core'
import { Icon } from '@iconify/vue'
import moment from 'moment'

import modals from '../Modals/Helper'
import { degreesToCardinal } from '@/tools/helpers'
</script>

<template>
  <hr class="divider" :style="{ 'border-color': feature.properties['line-color'] }">
  <div class="text-center tags">
    <span class="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounde" :title="feature.properties.issued_at">Issued {{ useTimeAgo(feature.properties.issued_at) }}</span>

    <span v-if="moment.utc(feature.properties.expires_at).isBefore(moment.utc())" class="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded" :title="feature.properties.expires_at">Expired {{ useTimeAgo(feature.properties.expires_at) }}!</span>
    <span v-else class="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded" :title="feature.properties.expires_at">Expires {{ useTimeAgo(feature.properties.expires_at) }}</span>

    <span v-if="feature.properties.tags.SOURCE" class="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded">Source: {{ feature.properties.tags.SOURCE }}</span>

    <span v-if="feature.properties.tags.TORNADO" class="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded">Tornado: {{ feature.properties.tags.TORNADO }}</span>

    <span v-if="feature.properties.tags.MAX_HAIL_SIZE && parseFloat(feature.properties.tags.MAX_HAIL_SIZE) > 0" class="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded">Max Hail Size: {{ feature.properties.tags.MAX_HAIL_SIZE }}</span>

    <span v-if="feature.properties.tags.HAIL && parseFloat(feature.properties.tags.HAIL) > 0" class="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded">Hail: {{ feature.properties.tags.HAIL }}</span>

    <span v-if="feature.properties.tags.MAX_WIND_GUST && parseFloat(feature.properties.tags.MAX_WIND_GUST) > 0" class="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded">Max Wind Gust: {{ feature.properties.tags.MAX_WIND_GUST }}</span>

    <span v-if="feature.properties.tags.WIND && parseFloat(feature.properties.tags.WIND) > 0" class="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded">Wind: {{ feature.properties.tags.WIND }}</span>

    <span v-if="feature.properties.tags.TIME_MOT_LOC" class="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded">Motion: {{ degreesToCardinal(motionDirection) }} ({{ motionDirection }}&deg;) at {{ feature.properties.tags.TIME_MOT_LOC.speed }} kts</span>

    <span v-if="feature.properties.office" class="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded">Office: {{ feature.properties.office }}</span>

    <button v-if="feature.properties.previous" type="button" class="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-2.5 py-1 text-center me-2 mb-2" @click="onShowPreviousClick($event)"><Icon icon="clarity:history-line" class="inline h-5 w-5 mr-1" />See previous warning</button>
    <button v-if="feature.properties.next" type="button" class="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-2.5 py-1 text-center me-2 mb-2" @click="onShowNextClick($event)"><Icon icon="clarity:history-line" class="inline h-5 w-5 mr-1" />See next warning</button>

    <button type="button" class="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl rounded-lg text-xs px-2.5 py-1 relative top-[-2px] text-center me-2 mb-2" @click="onShareClick($event)"><Icon icon="ion:share-outline" class="inline mr-1 size-5" /><span class="relative top-[2px] font-bold">Share</span></button>
  </div>

  <pre class="text">{{ feature.properties.text }}</pre>

  <hr>
  <p class="text-sm text-slate-600">The information is provided to us in realtime from the National Weather Service (NWS).</p>
</template>

<script>
export default {
  name: 'WarningsModal',
  props: ['feature'],
  computed: {
    motionDirection() {
      return (this.feature.properties.tags.TIME_MOT_LOC.direction + 180) % 360
    }
  },
  emits: ['close'],
  methods: {
    onShareClick(e) {
      modals.share();
    },
    async onShowPreviousClick(e) {
      // TODO
      // Don't use globals
      this.$emit('close')
      await window.map.warnings.fetchWarningFromArchive(this.feature.properties.previous)
    },
    async onShowNextClick(e) {
      // TODO
      // Don't use globals
      this.$emit('close')
      await window.map.warnings.fetchWarningFromArchive(this.feature.properties.next)
    }
  }
}
</script>

<style type="text/css" scoped>
.divider {
  border-width: 2px;
}

.tags span {
  display: inline-block;
  margin-bottom: 4px;
}

.text {
  white-space: pre-wrap;
}
</style>
