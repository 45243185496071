<template>
  <div v-if="metadata.impacts.length > 0">
    <table class="table-auto w-full">
      <thead>
        <tr>
          <th class="font-bold text-left text-xl border-b-2 pb-1">Risk</th>
          <th class="font-bold text-left text-xl border-b-2 pb-1">Area&nbsp;mi<sup>2</sup></th>
          <th class="font-bold text-left text-xl border-b-2 pb-1">Population</th>
        </tr>
      </thead>
      <tbody class="mt-2">
        <template v-for="(impact, idx) in metadata.impacts">
          <tr>
            <td class="pt-4">
              <u><strong>{{ impact.risk }}</strong></u>
            </td>
            <td class="pt-4">
              <u><strong>{{ formattedValue(impact.area_size) }}</strong></u>
            </td>
            <td class="pt-4">
              <u><strong>{{ formattedValue(impact.area_population) }}</strong></u>
            </td>
          </tr>
          <tr>
            <td colspan="3" class="pt-2 pb-4 border-b-2"><strong>Affected:</strong> {{ impact.places.join(', ') }}</td>
          </tr>
        </template>
      </tbody>
    </table>
    <!-- <hr class="mt-2 mb-4"> -->
  </div>
  <p v-html="metadata.discussion.replace(/(?:\r\n|\r|\n)/g, '<br>')"></p>
  <hr>
  <p class="text-sm text-slate-600">The outlooks are provided to us in realtime from the National Weather Service (NWS), specifically from the Storm Prediction Center (SPC). Last update at: {{ lastUpdatedAt }}</p>
</template>

<script>
import moment from 'moment'

export default {
  name: 'Discussion',
  props: ['metadata'],
  emits: ['close'],
  methods: {
    formattedValue(v) {
      if(v / 1000000.0 > 1) {
        return `${(v/1000000.0).toFixed(1)}M`
      }
      else {
        return `${(v/1000.0).toFixed(1)}K`
      }
    }
  },
  computed: {
    lastUpdatedAt() {
      return moment.utc(this.metadata.last_update_at).local().format('YYYY-MM-DD HH:mm:ss')
    }
  }
}
</script>
