<template>
    <button
        ref="locateButton"
        class="fixed bottom-28 bg-[#F6B91A] hover:bg-[#17274F] hover:text-[#F6B91A] right-4 w-12 h-12 rounded-full transition-all duration-300 group focus:outline-none"
        @click="locateUser" :disabled="isLocating || isError">
        <Icon v-if="isLocating" icon="eos-icons:three-dots-loading" class="inline size-8" />
        <Icon v-else :icon="buttonIcon" class="inline size-8" :class="{ 'rotate-45': !isError }" />
    </button>
</template>

<script setup lang="ts">
import { ref, onUnmounted } from 'vue';
import { overrideAPIs } from '@/tools/overrideAPIs';
import { Icon } from '@iconify/vue';
import mapboxgl from 'mapbox-gl';

// Ensure APIs are overridden
overrideAPIs();

const isLocating = ref(false);
const userLocation = ref(null);
const buttonIcon = ref('solar:map-arrow-up-outline');
const isError = ref(false);
const locateButton = ref(null);
const userMarker = ref(false);

const size = 128;

const pulsingDot = {
    width: size,
    height: size,
    data: new Uint8Array(size * size * 4),

    // When the layer is added to the map,
    // get the rendering context for the map canvas.
    onAdd: function () {
        const canvas = document.createElement('canvas');
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext('2d');
    },

    // Call once before every frame where the icon will be used.
    render: function () {
        const duration = 1000;
        const t = (performance.now() % duration) / duration;

        const radius = (size / 2) * 0.3;
        const outerRadius = (size / 2) * 0.7 * t + radius;
        const context = this.context;

        // Draw the outer circle.
        context.clearRect(0, 0, this.width, this.height);
        context.beginPath();
        context.arc(
            this.width / 2,
            this.height / 2,
            outerRadius,
            0,
            Math.PI * 2
        );
        context.fillStyle = `rgba(255, 100, 100, ${1 - t})`;
        context.fill();

        // Draw the inner circle.
        context.beginPath();
        context.arc(
            this.width / 2,
            this.height / 2,
            radius,
            0,
            Math.PI * 2
        );
        context.fillStyle = 'rgba(255, 87, 34, 1)';
        context.strokeStyle = 'white';
        context.lineWidth = 2 + 4 * (1 - t);
        context.fill();
        context.stroke();

        // Update this image's data with data from the canvas.
        this.data = context.getImageData(
            0,
            0,
            this.width,
            this.height
        ).data;

        // Continuously repaint the map, resulting
        // in the smooth animation of the dot.
        window.map.triggerRepaint();

        // Return `true` to let the map know that the image was updated.
        return true;
    }
};

const locateUser = async () => {
    if (!navigator._geolocation) {
        console.error('Geolocation is not supported.');
        return;
    }

    isLocating.value = true;
    isError.value = false;

    try {
        const permission = await navigator._geolocation.requestPermission();

        if (permission === 'denied') {
            throw new Error('Please enable location permissions in your settings to use this feature.');
        }

        const position = await Promise.race([
            navigator._geolocation.getCurrentPosition({
                enableHighAccuracy: true,
                maximumAge: 0
            }),
            new Promise((_, reject) => setTimeout(() => {
                reject(new Error('Location request timed out. Please try again.'))
            }, 15000))
        ]);

        userLocation.value = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
        };

        console.log('User location:', userLocation.value);

        const coords = [userLocation.value.longitude, userLocation.value.latitude];

        window.map.flyTo({
            center: coords,
            zoom: 12,
            essential: true
        });

        // Remove existing marker and layers if they exist
        if (userMarker.value) {
            window.map.removeLayer('layer-with-pulsing-dot');
            window.map.removeSource('dot-point');
        }

        // Add pulsing dot image
        window.map.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 });

        // Add source for the dot
        window.map.addSource('dot-point', {
            'type': 'geojson',
            'data': {
                'type': 'FeatureCollection',
                'features': [
                    {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Point',
                            'coordinates': coords
                        }
                    }
                ]
            }
        });

        // Add layer with pulsing dot
        window.map.addLayer({
            'id': 'layer-with-pulsing-dot',
            'type': 'symbol',
            'source': 'dot-point',
            'layout': {
                'icon-image': 'pulsing-dot'
            }
        });

        window.map.flyTo({
            center: coords,
            zoom: 12,
            essential: true
        });

        userMarker.value = true;

        await window.map.radar.turnOnClosestOnlineRadar(coords, 'REF');

    } catch (error) {
        console.error('Error getting location:', error);
        window._alert(error.message, 'Location Error');
        buttonIcon.value = 'bi:exclamation-lg';
        isError.value = true;
        setTimeout(() => {
            buttonIcon.value = 'solar:map-arrow-up-outline';
            isError.value = false;
        }, 2000);
    } finally {
        isLocating.value = false;
    }
};

onUnmounted(() => {
    if (userMarker.value) {
        window.map.removeLayer('layer-with-pulsing-dot');
        window.map.removeSource('dot-point');
    }
});
</script>

<style scoped>
/* Remove the .pulse class as it's no longer needed */
</style>
