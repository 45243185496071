<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import { useAppStore } from '@/stores/app'
import MapboxMap from '@/components/MapboxMap.vue'
import Colortable from '@/components/Colortable.vue'
import AppLogo from '@/components/AppLogo.vue'
import Outlooks from '@/components/Outlooks.vue'
import Sidebar from '@/components/Sidebar.vue'
import RadarDrawer from '@/components/RadarDrawer.vue'
import ShareButton from '@/components/ShareButton.vue'
import LocateMeButton from '../components/LocateMeButton.vue'
import { useDebounceFn, useNetwork } from '@vueuse/core'

import RecordingGlowEffect from '@/components/Extra/RecordingGlowEffect.vue'
import { useWarningsStore } from '@/stores/warnings';
import { useMesoscaleDiscussionsStore } from '@/stores/mesoscale_discussions';
import { useRadarTowersStore } from '@/stores/radar_towers';
import { storeToRefs } from 'pinia';

const isWeatherWiseBridgeDefined = ref(false);
const userbackButtonStyle = computed(() => isWeatherWiseBridgeDefined.value ? '10.5rem' : '7rem');

const appStore = useAppStore();

// Can't use v-bind because the userback element is outside of the app's root element
function updateUserbackButtonStyle() {
  const styleElement = document.createElement('style');
  styleElement.id = 'userback-button-style';
  styleElement.textContent = `
    .userback-button {
      bottom: ${userbackButtonStyle.value} !important;
    }
  `;

  const existingStyle = document.getElementById('userback-button-style');
  if (existingStyle) {
    existingStyle.replaceWith(styleElement);
  } else {
    document.head.appendChild(styleElement);
  }
}

onMounted(() => {
    isWeatherWiseBridgeDefined.value = typeof window.WeatherWiseBridge !== 'undefined';
    updateUserbackButtonStyle();
});

watch(userbackButtonStyle, updateUserbackButtonStyle);

const refreshData = useDebounceFn(() => {
  useWarningsStore().load()
  useMesoscaleDiscussionsStore().load()

  if (window.map && window.map.tropical) {
    window.map.tropical.fetchAndRenderStorms()
    window.map.tropical.fetchAndRenderDisturbances()
  }

  const radarTowerStore = useRadarTowersStore()
  const activeTower = radarTowerStore.activeTower
  const {activeProductCode} = storeToRefs(radarTowerStore)

  if (activeTower) {
    window.map.radar.loadRadarData(activeTower, activeProductCode.value)
  }
}, 500)

const { isOnline } = useNetwork()

watch(isOnline, (online) => {
  if (online) {
    refreshData()
  }
})

window.addEventListener('message', (event) => {
  try {
    if (typeof event.data === 'string') {
      const data = JSON.parse(event.data || event.nativeEvent?.data);
      if (data.type === 'REFRESH_DATA') {
        console.log('REFRESH_DATA')
        refreshData()
      }
    }
  } catch (error) {
    console.error(`Error listening for message`, error)
  }
}, true)
</script>

<template>
  <MapboxMap />
  <Colortable />
  <AppLogo />
  <Sidebar />

  <Outlooks v-if="appStore.isReady() && appStore.mode === 'OUTLOOKS'" />
  <RadarDrawer v-if="appStore.isReady() && appStore.mode === 'RADAR'" />

  <LocateMeButton v-if="isWeatherWiseBridgeDefined" />
  <ShareButton />

  <RecordingGlowEffect />
</template>
