import { toRaw } from 'vue'

import { distance } from '@turf/turf'
import { useHead } from '@unhead/vue'
import moment from 'moment'
import { useModal, useModalSlot } from 'vue-final-modal'
import { useNProgress } from '@vueuse/integrations/useNProgress'

const { progress, isLoading } = useNProgress(1)

import { PAGE_TITLE } from '@/brand'

import { stopPropagation } from '@/tools/mapbox-map'
import { findClosestDate, wait } from '@/tools/helpers'
import { circle } from '@/tools/graphics'
import { MODE_NONE, MODE_REALTIME, MODE_HISTORICAL_TO_REALTIME } from '@/tools/constants'

import radarTowers from '@/data/radar_towers.geojson'
import { Lookup as ColormapLookup } from '@/data/Colortables/Radar.js'

import BaseRadar from './Radar/BaseRadar'
import StormTracks from './Radar/StormTracks'
import Lightning from './Radar/Lightning'
import { RadarRenderer } from "./Radar/Renderer/";

import SimpleModal from './Modals/Templates/Simple.vue'
import RadarProductHelpModal from './Radar/RadarProductHelp.vue'

import socket from '@/logic/Socket'

import { useRadarTowersStore, DEFAULT_RADAR_REF_PRODUCT_CODE, DEFAULT_RADAR_VEL_PRODUCT_CODE } from '@/stores/radar_towers'

export default class Radar extends BaseRadar {
  constructor(map) {
    super(map)

    this.radarTowersStore = useRadarTowersStore()

    this.mode = MODE_NONE;

    this.sourceId = 'radar-towers-source'
    this.layerId = 'radar-towers-layer'
    this.radarRendererLayerId = 'radar-renderer';

    this.map.addLayer({
      id: 'top-top-radar-layer',
      type: 'line',
      source: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      }
    })

    this.map.addLayer({
      id: 'bottom-top-radar-layer',
      type: 'line',
      source: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      }
    }, "top-top-radar-layer")

    this.map.addLayer({
      id: 'top-middle-radar-layer',
      type: 'line',
      source: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      }
    }, "land-structure-polygon")

    this.map.addLayer({
      id: 'bottom-middle-radar-layer',
      type: 'line',
      source: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      }
    }, "top-middle-radar-layer")

    this.stormTracks = new StormTracks(map);
    this.lightning = new Lightning(map);
    this.renderer = new RadarRenderer(map, this.radarRendererLayerId, "top-middle-radar-layer");
    this.renderer.setOpacity(1);

    this.bufferedRadarScans = {};
    this.bufferedRadarScansLimit = 0;
    this.playbackIdx = 0;

    this.activeSocketRooms = [];

    const copy = JSON.parse(JSON.stringify(radarTowers))

    copy.features = copy.features.map((f) => {
      const secondaryRadar = f.properties.secondary;

      f.properties.active = this.radarTowersStore.activeTowerId === f.properties.id;

      f.properties['icon-image'] = this.towerIcon(f)
      f.properties['icon-size'] = secondaryRadar ? 0.8 : 1
      f.properties['symbol-sort-key'] = secondaryRadar ? 2 : 1
      return f
    })

    this.towers = copy;

    // Generate and load icons
    const dotPrimary = circle({
      size: 21*2,
      color: '#10234E',
      border_color: '#FFFFFF',
      border_size: 3
    });
    this.map.addImage('radar-tower-primary', dotPrimary, { pixelRatio: 2 });

    const dotSecondary = circle({
      size: 21*2,
      color: '#F6BA12',
      border_color: '#FFFFFF',
      border_size: 3
    });
    this.map.addImage('radar-tower-secondary', dotSecondary, { pixelRatio: 2 });

    const dotActive = circle({
      size: 21*2,
      color: '#018101',
      border_color: '#FFFFFF',
      border_size: 3
    });
    this.map.addImage('radar-tower-active', dotActive, { pixelRatio: 2 });

    // Render radar markers
    this.map.addSource(this.sourceId, {
      type: 'geojson',
      data: this.towers
    })

    this.map.addLayer({
      id: this.layerId,
      type: 'symbol',
      source: this.sourceId,
      layout: {
        'icon-image': ['get', 'icon-image'],
        'icon-size': ['get', 'icon-size'],
        'icon-padding': 1,
        'symbol-sort-key': ['get', 'symbol-sort-key'],
        'icon-pitch-alignment': 'map'
      }
    }, 'top-top-radar-layer')

    this.map.on('click', this.layerId, stopPropagation(async (e) => {
      // console.log('click',e)
      if(e.features.length === 0) return;

      const feature = this.towers.features.find((f) => f.properties.id === e.features[0].properties.id);
      if (feature === undefined) return;

      // console.log(feature)

      if (feature.properties.active) {
        this.closeAllRadarTowers()
      } else {
        // Close all other radar towers
        this.closeAllRadarTowers()

        this.turnOnRadar(feature)
      }
    }))

    const activeTower = this.towers.features.find(f => f.properties.active);
    if(activeTower !== undefined) {
      this.turnOnRadar(activeTower)
    }

    this.hide()
  }

  clearPlaybackState() {
    // Return mdoe back to realtime
    this.mode = MODE_REALTIME;

    // Stop playing incase it is
    this.radarTowersStore.isPlaying = false;

    // Clear radar buffered state
    this.clearBufferedHistoricalState();

    // Return lightning back to realtime (if not)
    this.lightning.clearBufferedHistoricalState();

    // Return warnigns back to realtime (if not)
    this.map.warnings.clearBufferedHistoricalState();

    // Return warnigns back to realtime (if not)
    this.map.mesoscaleDiscussions.clearBufferedHistoricalState();

    // Reset playback scan index
    this.playbackIdx = 0;
  }

  closeAllRadarTowers() {
    this.clearPlaybackState();

    this.towers.features.forEach((f) => {
      if (! f.properties.active) return

      this.removeRadar(f)
      f.properties.active = false
      f.properties['icon-image'] = this.towerIcon(f)
    });

    this.map.getSource(this.sourceId).setData(toRaw(this.towers))
  }

  async turnOnRadar(feature, product = null) {
    useHead({
      title: `${feature.properties.code} - ${PAGE_TITLE}`
    })

    this.mode = MODE_REALTIME;

    feature.properties.active = true
    feature.properties['icon-image'] = this.towerIcon(feature)
    this.map.getSource(this.sourceId).setData(toRaw(this.towers))

    this.radarTowersStore.setActiveTower(feature)
    this.radarTowersStore.setAvailableProducts(feature.properties.products)
    this.radarTowersStore.clearScanDatetime()
    this.radarTowersStore.clearScanVcp()

    if(product === null) {
      product = this.radarTowersStore.activeProductCode;
    }
    else {
      this.radarTowersStore.activeProductCode = product;
    }

    const room = `radar:${feature.properties.id}:${product}`
    this.activeSocketRooms.push(room)
    socket.roomJoin(room)
    socket.on(room, async (data) => {
      console.log('Radar update', room, data, `Mode: ${this.mode}`)

      // TODO
      // Load the url provided in the data

      if(this.mode === MODE_REALTIME) {
        this.loadLatestScan(feature, product)
      }
      else if(this.mode === MODE_HISTORICAL_TO_REALTIME) {
        if(this.bufferedRadarScans[product] === undefined) return false;

        const scan = await this.loadLatestFile(feature.properties.id, product);
        this.bufferedRadarScans[product].push(scan);

        while(this.bufferedRadarScans[product].length > this.bufferedRadarScansLimit) {
          this.bufferedRadarScans[product].shift();
        }
      }
    })

    // Check that storm tracks are supported
    if(feature.properties.products.includes('NST')) {
      const product = 'NST'
      const room = `radar:${feature.properties.id}:${product}`
      this.activeSocketRooms.push(room)
      socket.roomJoin(room)
      socket.on(room, async (data) => {
        console.log('Radar update', room, data, `Mode: ${this.mode}`)

        if(this.mode === MODE_REALTIME) {
          // TODO
          // Load the url provided in the data
          this.loadLatestStormTrack(feature)
        }
        else if(this.mode === MODE_HISTORICAL_TO_REALTIME) {
          if(this.bufferedRadarScans[product] === undefined) return false;

          const scan = await this.loadLatestFile(feature.properties.id, product);
          this.bufferedRadarScans[product].push(scan);

          while(this.bufferedRadarScans[product].length > this.bufferedRadarScansLimit) {
            this.bufferedRadarScans[product].shift();
          }
        }
      })
    }

    await this.loadRadarData(feature, product);
  }

  async loadRadarData(feature, product) {
    const promises = [];

    promises.push(this.loadLatestScan(feature, product))

    if (feature.properties.products.includes('NST')) {
      promises.push(this.loadLatestStormTrack(feature))
    }

    promises.push(this.lightning.load(feature.properties.id));

    await Promise.allSettled(promises);
  }

  turnOnRadarViaId(id) {
    const feature = this.towers.features.find(f => f.properties.id === id)

    if(feature === undefined) return false

    this.closeAllRadarTowers()

    this.turnOnRadar(feature)

    return feature
  }

  changeRadarProduct(id, productCode) {
    const feature = this.towers.features.find(f => f.properties.id === id)

    if(feature === undefined) return false

    this.clearPlaybackState()
    this.stopListeningToRooms()

    this.radarTowersStore.activeProductCode = productCode;

    this.turnOnRadar(feature)
  }

  stopListeningToRooms() {
    this.activeSocketRooms.forEach(room => {
      socket.roomLeave(room)
      socket.removeAllListeners(room)
    })
    this.activeSocketRooms = []
  }

  removeRadar(feature) {
    useHead({
      title: PAGE_TITLE
    })

    this.radarTowersStore.clear()

    const towerId = feature.properties.id

    this.stopListeningToRooms();

    this.renderer.clear();

    if(feature.properties.products.includes('NST')) {
      this.stormTracks.clear(towerId)
    }

    this.lightning.clear(towerId);
  }

  getClosestTowers(position) {
    const majorTowers = this.towers.features.filter(f => !f.properties.secondary).map(f => {
      return {
        feature: f,
        distance: distance(f.geometry.coordinates, position, { units: "kilometers" })
      }
    })

    majorTowers.sort((a, b) => {
      return a.distance - b.distance;
    })

    return majorTowers;
  }

  async turnOnClosestRadar(position) {
    const majorTowers = this.getClosestTowers(position);

    if(majorTowers.length == 0) return;

    if(this.radarTowersStore.activeTowerId === majorTowers[0].feature.properties.id) return;

    this.closeAllRadarTowers()

    await this.turnOnRadar(majorTowers[0].feature)
  }

  async turnOnClosestOnlineRadar(position, product = 'REF') {
    // Now locate the nearest ONLINE radar (within 300 kilometers)
    // And turn on the lowest elevation ref product
    const closestTowers = this.map.radar.getClosestTowers(position).filter(f => f.distance < 300);

    if(closestTowers.length === 0) {
      let message = 'Could not locate a nearby radar tower.'
      throw new Error(message)
    }

    const defaultProductCodes = (() => {
      if(product === 'REF') return DEFAULT_RADAR_REF_PRODUCT_CODE;
      else if(product === 'VEL') return DEFAULT_RADAR_VEL_PRODUCT_CODE;

      return [];
    })();

    // Loop through each close tower until we find a ref. scan
    // That is not too old (max 10 mins.)
    for (const t of closestTowers) {
      const tower = t.feature;
      const towerId = tower.properties.id;
      const product = (() => {
        for(const p of defaultProductCodes) {
          if(tower.properties.products.includes(p)) return p;
        }

        return null;
      })();

      if(product === null) continue;

      const scan = await this.loadLatestFile(towerId, product);

      const age = moment.utc().diff(moment.utc(scan.datetime), 'seconds');

      // Check if scan is too old
      if(age < 60 * 10) {
        this.closeAllRadarTowers();
        await this.turnOnRadar(tower, product);
        break;
      }
    }
  }

  async loadLatestScan(feature, product) {
    const towerId = feature.properties.id

    const scan = await this.loadLatestFile(towerId, product);

    this.drawScan(scan, towerId, product)
  }

  drawScan(scan, towerId, product) {
    if(scan === undefined || scan === null || typeof scan !== 'object') {
      return console.log(`Bad radar scan: ${towerId} ${product}`);
    }

    if(ColormapLookup[product] === undefined) {
      return console.error(`Missing colormap for product: ${product}`);
    }

    const colormap = ColormapLookup[product];

    // console.log({colormap, product})

    this.renderer.reset();
    this.renderer.clear();
    this.renderer.setColormap(colormap.colors);
    this.renderer.setMinMax(colormap.min, colormap.max);
    this.renderer.draw(scan);

    this.radarTowersStore.setColorMap(colormap)
    this.radarTowersStore.setScanDatetime(scan.datetime)

    const vcp = scan?.metadata?.vcp ?? null;
    this.radarTowersStore.setScanVcp(vcp);
  }

  async loadLatestStormTrack(feature) {
    const towerId = feature.properties.id

    const tracks = await this.loadLatestFile(towerId, 'NST')
    // console.log(tracks)

    this.stormTracks.draw(towerId, tracks)
  }

  async loadHistory(towerId, product, limit) {
    // Check if we're already buffered data for this product
    // If so, exit early
    if(typeof this.bufferedRadarScans[product] === 'object' && this.bufferedRadarScans[product].length <= limit) {
      return false;
    }
    this.bufferedRadarScansLimit = limit;

    const feature = this.towers.features.find((f) => f.properties.id === towerId)
    if (feature === undefined) return;

    // Clear previously buffered state
    this.clearPlaybackState();

    // Now set the back
    // The clear function above will set it back to realtime
    this.mode = MODE_HISTORICAL_TO_REALTIME;

    const bufferNST = feature.properties.products.includes('NST');
    const bufferLightning = true;
    const bufferWarnings = true;
    const bufferMesoscaleDiscussions = true;

    let requestsToMake = 0;

    let percentIncrement = 0;

    progress.value = 0.1;

    const limitedProductList = await this.fetchLatestList(towerId, product, limit);
    requestsToMake+=limitedProductList.length;
    const requestsProduct = limitedProductList.map((file) => {
      return new Promise(async (resolve, reject) => {
        const r = await this.fetchJson(towerId, product, file);
        progress.value+=percentIncrement
        resolve(r)
      })
    });

    // console.log({requestsProduct})
    // return

    let limitedNSTList, requestsNST;

    // TODO
    // This could be optimised to fetch the lists in parallel
    if(bufferNST) {
      limitedNSTList = await this.fetchLatestList(towerId, 'NST', limit);
      requestsToMake+=limitedNSTList.length;
      requestsNST = limitedNSTList.map((file) => {
        return new Promise(async (resolve, reject) => {
          const r = await this.fetchJson(towerId, 'NST', file);
          progress.value+=percentIncrement
          resolve(r)
        })
      });
    }

    progress.value = 0.2;

    // Subtract 0.1 for the requests made below the radar products (warnings, etc.)
    percentIncrement = (1.0 - progress.value - 0.1) / requestsToMake;

    // console.log(requestsToMake, percentIncrement)

    // TODO
    // This will need a rethink once the limit is higher
    const response = await Promise.allSettled(requestsProduct)

    this.bufferedRadarScans[product] = response.filter(r => r.status === 'fulfilled').map(r => r.value);

    if(bufferNST) {
      // TODO
      // This will need a rethink once the limit is higher
      const response = await Promise.allSettled(requestsNST)

      this.bufferedRadarScans['NST'] = response.filter(r => r.status === 'fulfilled').map(r => r.value);
    }

    // We'll assume there is a radar scan every 6 mins. on average
    // NEXRAD is every 3-6
    // CAN is every 6
    const secsToLoad = 60 * 60;

    const promises = [];

    if(bufferLightning) {
      promises.push(this.lightning.loadHistory(towerId, secsToLoad));
    }

    if(bufferWarnings) {
      promises.push(this.map.warnings.loadHistory(secsToLoad));
    }

    if(bufferMesoscaleDiscussions) {
      promises.push(this.map.mesoscaleDiscussions.loadHistory(secsToLoad));
    }

    await Promise.allSettled(promises);

    this.radarTowersStore.hasBufferedScans = true;

    progress.value = 1.0
  }

  clearBufferedHistoricalState() {
    for(const key in this.bufferedRadarScans) {
      delete this.bufferedRadarScans[key]
    }

    this.radarTowersStore.hasBufferedScans = false;
  }

  async playScans(towerId, product, maxSteps = Infinity) {
    // If data has no been buffered, then exit early
    if(this.bufferedRadarScans[product] === undefined) return false;

    const feature = this.towers.features.find((f) => f.properties.id === towerId)
    if (feature === undefined) return;

    const bufferedHistoricalToRealtime = true;

    const playNST = feature.properties.products.includes('NST') && typeof this.bufferedRadarScans['NST'] === 'object';
    const playLightning = true;
    const playWarnings = true;
    const playMesoscaleDiscussions = true;

    this.radarTowersStore.isPlaying = true;

    let currentStep = 0;

    const length = this.bufferedRadarScans[product].length;
    while(this.radarTowersStore.isPlaying && currentStep < maxSteps) {
      if(map.isUserInteracting) {
        console.log('Skiping playback due to interaction');
      }
      else {
        const scan = this.bufferedRadarScans[product][this.playbackIdx];
        const dt = scan.datetime;
        const momentDt = moment.utc(dt);

        this.drawScan(scan, towerId, product);

        const isLastScan = (this.playbackIdx + 1) === length;
        const displayFuture = bufferedHistoricalToRealtime && isLastScan;

        if(playNST) {
          const NSTdates = this.bufferedRadarScans['NST'].map(s => s.datetime)

          const closest = findClosestDate(NSTdates, momentDt);

          // Check if the closest date is within 15 minutes
          const closestNST = (closest !== null && Math.abs(moment.utc(closest).diff(momentDt, 'minutes')) <= 15) ? closest : null;

          if(closestNST !== null) {
            const tracks = this.bufferedRadarScans['NST'].find(s => s.datetime === closestNST);

            this.stormTracks.draw(towerId, tracks);
          }
        }

        if(playLightning) {
          this.lightning.drawHistory(momentDt, displayFuture);
        }

        if(playWarnings) {
          this.map.warnings.drawHistory(momentDt, displayFuture);
        }

        if(playMesoscaleDiscussions) {
          this.map.mesoscaleDiscussions.drawHistory(momentDt, displayFuture);
        }

        ++currentStep;
        ++this.playbackIdx;
        if(this.playbackIdx >= length) this.playbackIdx = 0;
      }

      let delay = 150;
      if(this.playbackIdx === 0) {
        delay*=4;
      }

      await wait(delay);
    }
  }

  towerIcon(f) {
    if (f.properties.active) return 'radar-tower-active'

    const secondaryRadar = f.properties.secondary;

    return secondaryRadar ? 'radar-tower-secondary' : 'radar-tower-primary'
  }

  openRadarProductHelpModal(productGroup) {
    const modal = useModal({
      defaultModelValue: true,
      component: SimpleModal,
      attrs: {
        title: productGroup.name
      },
      slots: {
        default: useModalSlot({
          component: RadarProductHelpModal,
          attrs: {
            text: productGroup.help,
            onClose() {
              modal.close()
            },
          }
        })
      },
    })

    return modal;
  }

  show() {
    for(const layerId of [this.layerId, this.radarRendererLayerId]) {
      this.map.setLayoutProperty(layerId, 'visibility', 'visible');
    }

    this.lightning.show();
    this.stormTracks.show();
  }

  hide() {
    for(const layerId of [this.layerId, this.radarRendererLayerId]) {
      this.map.setLayoutProperty(layerId, 'visibility', 'none');
    }

    this.lightning.hide();
    this.stormTracks.hide();
  }
}
