export default Object.freeze({
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -92.262194,
          34.8365,
          649
        ]
      },
      "properties": {
        "id": "KLZK",
        "code": "KLZK",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -96.968056,
          32.926111,
          623
        ]
      },
      "properties": {
        "id": "TDAL",
        "code": "TDAL",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -77.528889,
          39.083889,
          472
        ]
      },
      "properties": {
        "id": "TIAD",
        "code": "TIAD",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -121.677833,
          38.501111,
          144
        ]
      },
      "properties": {
        "id": "KDAX",
        "code": "KDAX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -85.7,
          41.358611,
          1056
        ]
      },
      "properties": {
        "id": "KIWX",
        "code": "KIWX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -157.180278,
          21.132778,
          1444
        ]
      },
      "properties": {
        "id": "PHMO",
        "code": "PHMO",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -92.209722,
          46.836944,
          1542
        ]
      },
      "properties": {
        "id": "KDLH",
        "code": "KDLH",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "NYB",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "NYG",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "NYX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "NYC",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "NYK",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "NYH",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -100.864444,
          48.393056,
          1590
        ]
      },
      "properties": {
        "id": "KMBX",
        "code": "KMBX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "NZB",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "NZG",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "NZX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "NZC",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "NZK",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "NZH",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -120.397917,
          34.83855,
          1354
        ]
      },
      "properties": {
        "id": "KVBX",
        "code": "KVBX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -95.564161,
          36.175131,
          749
        ]
      },
      "properties": {
        "id": "KINX",
        "code": "KINX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -94.361889,
          35.290417,
          737
        ]
      },
      "properties": {
        "id": "KSRX",
        "code": "KSRX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -87.73,
          41.651111,
          764
        ]
      },
      "properties": {
        "id": "TMDW",
        "code": "TMDW",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -96.918056,
          33.065,
          584
        ]
      },
      "properties": {
        "id": "TDFW",
        "code": "TDFW",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -73.881111,
          40.588889,
          112
        ]
      },
      "properties": {
        "id": "TJFK",
        "code": "TJFK",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -77.007361,
          36.98405,
          255
        ]
      },
      "properties": {
        "id": "KAKQ",
        "code": "KAKQ",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -91.191111,
          43.822778,
          1357
        ]
      },
      "properties": {
        "id": "KARX",
        "code": "KARX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -78.736781,
          42.948789,
          790
        ]
      },
      "properties": {
        "id": "KBUF",
        "code": "KBUF",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "NZB",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "NZG",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "NZX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "NZC",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "NZK",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "NZH",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -81.703167,
          24.5975,
          89
        ]
      },
      "properties": {
        "id": "KBYX",
        "code": "KBYX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -73.166431,
          44.511,
          431
        ]
      },
      "properties": {
        "id": "KCXX",
        "code": "KCXX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -114.89165,
          35.70135,
          4948
        ]
      },
      "properties": {
        "id": "KESX",
        "code": "KESX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -101.700278,
          39.366944,
          3716
        ]
      },
      "properties": {
        "id": "KGLD",
        "code": "KGLD",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -97.325556,
          47.527778,
          1083
        ]
      },
      "properties": {
        "id": "KMVX",
        "code": "KMVX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -85.78975,
          32.53665,
          560
        ]
      },
      "properties": {
        "id": "KMXX",
        "code": "KMXX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -89.873333,
          35.344722,
          435
        ]
      },
      "properties": {
        "id": "KNQA",
        "code": "KNQA",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -118.852931,
          45.69065,
          1580
        ]
      },
      "properties": {
        "id": "KPDT",
        "code": "KPDT",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "NYB",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "NYG",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "NYX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "NYC",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "NYK",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "NYH",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -98.127717,
          36.740617,
          1258
        ]
      },
      "properties": {
        "id": "KVNX",
        "code": "KVNX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -106.120033,
          33.077,
          4270
        ]
      },
      "properties": {
        "id": "KHDX",
        "code": "KHDX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          127.285561,
          37.207569,
          1521
        ]
      },
      "properties": {
        "id": "RKSG",
        "code": "RKSG",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -70.933056,
          42.158056,
          262
        ]
      },
      "properties": {
        "id": "TBOS",
        "code": "TBOS",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -95.241944,
          29.516111,
          118
        ]
      },
      "properties": {
        "id": "THOU",
        "code": "THOU",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -78.696944,
          36.001944,
          515
        ]
      },
      "properties": {
        "id": "TRDU",
        "code": "TRDU",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -67.806431,
          46.03925,
          860
        ]
      },
      "properties": {
        "id": "KCBW",
        "code": "KCBW",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -75.984722,
          42.199694,
          1703
        ]
      },
      "properties": {
        "id": "KBGM",
        "code": "KBGM",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -97.51125,
          27.784017,
          142
        ]
      },
      "properties": {
        "id": "KCRP",
        "code": "KCRP",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "NZB",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "NZG",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "NZX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "NZC",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "NZK",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "NZH",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -89.984444,
          32.279944,
          609
        ]
      },
      "properties": {
        "id": "KDGX",
        "code": "KDGX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "NZB",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "NZG",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "NZX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "NZC",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "NZK",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "NZH",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -74.410731,
          39.947089,
          230
        ]
      },
      "properties": {
        "id": "KDIX",
        "code": "KDIX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -99.254333,
          32.5385,
          1582
        ]
      },
      "properties": {
        "id": "KDYX",
        "code": "KDYX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -106.698,
          31.873056,
          4218
        ]
      },
      "properties": {
        "id": "KEPZ",
        "code": "KEPZ",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -97.443056,
          37.654444,
          1400
        ]
      },
      "properties": {
        "id": "KICT",
        "code": "KICT",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -100.4925,
          31.371278,
          2004
        ]
      },
      "properties": {
        "id": "KSJT",
        "code": "KSJT",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -114.656711,
          32.495281,
          239
        ]
      },
      "properties": {
        "id": "KYUX",
        "code": "KYUX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          126.622222,
          35.924167,
          192
        ]
      },
      "properties": {
        "id": "RKJK",
        "code": "RKJK",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -104.52611,
          39.728056,
          5702
        ]
      },
      "properties": {
        "id": "TDEN",
        "code": "TDEN",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -81.042194,
          32.655528,
          229
        ]
      },
      "properties": {
        "id": "KCLX",
        "code": "KCLX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "NZB",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "NZG",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "NZX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "NZC",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "NZK",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "NZH",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -88.111111,
          44.498633,
          823
        ]
      },
      "properties": {
        "id": "KGRB",
        "code": "KGRB",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -82.219833,
          34.883306,
          1069
        ]
      },
      "properties": {
        "id": "KGSP",
        "code": "KGSP",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "NYB",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "NYG",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "NYX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "NYC",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "NYK",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "NYH",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -85.943889,
          37.975278,
          833
        ]
      },
      "properties": {
        "id": "KLVX",
        "code": "KLVX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -76.876189,
          34.775908,
          145
        ]
      },
      "properties": {
        "id": "KMHX",
        "code": "KMHX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -117.626778,
          47.680417,
          2449
        ]
      },
      "properties": {
        "id": "KOTX",
        "code": "KOTX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -93.400419,
          37.235239,
          1375
        ]
      },
      "properties": {
        "id": "KSGF",
        "code": "KSGF",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "NYB",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "NYG",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "NYX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "NYC",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "NYK",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "NYH",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -84.328944,
          30.397583,
          177
        ]
      },
      "properties": {
        "id": "KTLH",
        "code": "KTLH",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -155.568889,
          19.095,
          1461
        ]
      },
      "properties": {
        "id": "PHWA",
        "code": "PHWA",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -95.566944,
          30.065,
          253
        ]
      },
      "properties": {
        "id": "TIAH",
        "code": "TIAH",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -92.933056,
          44.871111,
          1122
        ]
      },
      "properties": {
        "id": "TMSP",
        "code": "TMSP",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -106.823889,
          35.149722,
          5951
        ]
      },
      "properties": {
        "id": "KABX",
        "code": "KABX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -104.806031,
          41.151919,
          6193
        ]
      },
      "properties": {
        "id": "KCYS",
        "code": "KCYS",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -78.429108,
          33.98915,
          145
        ]
      },
      "properties": {
        "id": "KLTX",
        "code": "KLTX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -77.4875,
          38.976111,
          404
        ]
      },
      "properties": {
        "id": "KLWX",
        "code": "KLWX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -147.501431,
          65.035114,
          2707
        ]
      },
      "properties": {
        "id": "PAPD",
        "code": "PAPD",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -97.51,
          35.276111,
          1309
        ]
      },
      "properties": {
        "id": "TOKC",
        "code": "TOKC",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -81.326111,
          28.343889,
          171
        ]
      },
      "properties": {
        "id": "TMCO",
        "code": "TMCO",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -82.518056,
          27.86,
          92
        ]
      },
      "properties": {
        "id": "TTPA",
        "code": "TTPA",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -97.30315,
          32.573,
          777
        ]
      },
      "properties": {
        "id": "KFWS",
        "code": "KFWS",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -119.632139,
          36.314181,
          340
        ]
      },
      "properties": {
        "id": "KHNX",
        "code": "KHNX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -116.8027,
          40.73955,
          6895
        ]
      },
      "properties": {
        "id": "KLRX",
        "code": "KLRX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -122.717369,
          42.081169,
          7561
        ]
      },
      "properties": {
        "id": "KMAX",
        "code": "KMAX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "NXB",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "NXG",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "NXC",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "NXK",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "NXH",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -93.565528,
          44.848889,
          1101
        ]
      },
      "properties": {
        "id": "KMPX",
        "code": "KMPX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -98.441944,
          40.320833,
          2057
        ]
      },
      "properties": {
        "id": "KUEX",
        "code": "KUEX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -90.403056,
          30.021944,
          98
        ]
      },
      "properties": {
        "id": "TMSY",
        "code": "TMSY",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -124.292167,
          40.498583,
          2516
        ]
      },
      "properties": {
        "id": "KBHX",
        "code": "KBHX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -81.859867,
          41.413217,
          860
        ]
      },
      "properties": {
        "id": "KCLE",
        "code": "KCLE",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "NZX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "NZC",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "NZK",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "NZH",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -85.921667,
          30.565033,
          221
        ]
      },
      "properties": {
        "id": "KEVX",
        "code": "KEVX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -86.280278,
          39.7075,
          887
        ]
      },
      "properties": {
        "id": "KIND",
        "code": "KIND",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -87.548333,
          46.531111,
          1525
        ]
      },
      "properties": {
        "id": "KMQT",
        "code": "KMQT",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -97.277761,
          35.333361,
          1278
        ]
      },
      "properties": {
        "id": "KTLX",
        "code": "KTLX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -135.529167,
          56.852778,
          272
        ]
      },
      "properties": {
        "id": "PACG",
        "code": "PACG",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -96.729444,
          43.587778,
          1495
        ]
      },
      "properties": {
        "id": "KFSD",
        "code": "KFSD",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -83.001806,
          30.890278,
          330
        ]
      },
      "properties": {
        "id": "KVAX",
        "code": "KVAX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -124.106667,
          47.116944,
          366
        ]
      },
      "properties": {
        "id": "KLGX",
        "code": "KLGX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "NYB",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "NYG",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "NYX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "NYC",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "NYK",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "NYH",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -80.491111,
          25.758056,
          125
        ]
      },
      "properties": {
        "id": "TMIA",
        "code": "TMIA",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -74.27,
          40.593056,
          135
        ]
      },
      "properties": {
        "id": "TEWR",
        "code": "TEWR",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -98.413333,
          45.455833,
          1383
        ]
      },
      "properties": {
        "id": "KABR",
        "code": "KABR",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -116.236033,
          43.490217,
          3172
        ]
      },
      "properties": {
        "id": "KCBX",
        "code": "KCBX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -90.580833,
          41.611667,
          851
        ]
      },
      "properties": {
        "id": "KDVN",
        "code": "KDVN",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -94.264472,
          38.81025,
          1092
        ]
      },
      "properties": {
        "id": "KEAX",
        "code": "KEAX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -104.545806,
          39.786639,
          5611
        ]
      },
      "properties": {
        "id": "KFTG",
        "code": "KFTG",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -86.083611,
          34.930556,
          1859
        ]
      },
      "properties": {
        "id": "KHTX",
        "code": "KHTX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -112.862183,
          37.59105,
          10757
        ]
      },
      "properties": {
        "id": "KICX",
        "code": "KICX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "NYB",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "NYG",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "NYX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "NYC",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "NYK",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "NYH",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -83.350833,
          32.675683,
          618
        ]
      },
      "properties": {
        "id": "KJGX",
        "code": "KJGX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -88.24,
          30.679444,
          289
        ]
      },
      "properties": {
        "id": "KMOB",
        "code": "KMOB",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "NYB",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "NYG",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "NYX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "NYC",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "NYK",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "NYH",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -83.401944,
          36.168611,
          1434
        ]
      },
      "properties": {
        "id": "KMRX",
        "code": "KMRX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -112.447778,
          41.262778,
          6594
        ]
      },
      "properties": {
        "id": "KMTX",
        "code": "KMTX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "NYB",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "NYG",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "NYX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "NYC",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "NYK",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "NYH",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -72.863917,
          40.865528,
          199
        ]
      },
      "properties": {
        "id": "KOKX",
        "code": "KOKX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -92.976111,
          31.155278,
          473
        ]
      },
      "properties": {
        "id": "KPOE",
        "code": "KPOE",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -108.4773,
          43.066089,
          5633
        ]
      },
      "properties": {
        "id": "KRIW",
        "code": "KRIW",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -96.23255,
          38.99695,
          1415
        ]
      },
      "properties": {
        "id": "KTWX",
        "code": "KTWX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -161.876389,
          60.791944,
          193
        ]
      },
      "properties": {
        "id": "PABC",
        "code": "PABC",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          144.811111,
          13.455833,
          386
        ]
      },
      "properties": {
        "id": "PGUA",
        "code": "PGUA",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "N0X",
          "NAX",
          "N1X",
          "N0C",
          "NAC",
          "N1C",
          "N0K",
          "NAK",
          "N1K",
          "N0H",
          "NAH",
          "N1H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -80.885,
          35.336944,
          869
        ]
      },
      "properties": {
        "id": "TCLT",
        "code": "TCLT",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -85.61,
          38.046111,
          732
        ]
      },
      "properties": {
        "id": "TSDF",
        "code": "TSDF",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -90.407,
          30.519000000000002,
          44
        ]
      },
      "properties": {
        "id": "KHDC",
        "code": "KHDC",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "NZB",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "NZG",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "NZX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "NZC",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "NZK",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "NZH",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -71.136861,
          41.955778,
          232
        ]
      },
      "properties": {
        "id": "KBOX",
        "code": "KBOX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -99.968889,
          37.760833,
          2671
        ]
      },
      "properties": {
        "id": "KDDC",
        "code": "KDDC",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -93.722869,
          41.7312,
          1095
        ]
      },
      "properties": {
        "id": "KDMX",
        "code": "KDMX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -83.313056,
          37.590833,
          1461
        ]
      },
      "properties": {
        "id": "KJKL",
        "code": "KJKL",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -80.654083,
          28.113194,
          149
        ]
      },
      "properties": {
        "id": "KMLB",
        "code": "KMLB",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -117.56075,
          35.09785,
          2873
        ]
      },
      "properties": {
        "id": "KEYX",
        "code": "KEYX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -159.5525,
          21.893889,
          340
        ]
      },
      "properties": {
        "id": "PHKI",
        "code": "PHKI",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -84.123056,
          40.021944,
          1020
        ]
      },
      "properties": {
        "id": "TDAY",
        "code": "TDAY",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -86.661944,
          35.98,
          817
        ]
      },
      "properties": {
        "id": "TBNA",
        "code": "TBNA",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -111.93,
          40.966944,
          4295
        ]
      },
      "properties": {
        "id": "TSLC",
        "code": "TSLC",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -97.436944,
          37.506944,
          1352
        ]
      },
      "properties": {
        "id": "TICH",
        "code": "TICH",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -70.256361,
          43.891306,
          474
        ]
      },
      "properties": {
        "id": "KGYX",
        "code": "KGYX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -119.462028,
          39.754056,
          8396
        ]
      },
      "properties": {
        "id": "KRGX",
        "code": "KRGX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "NYB",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "NYG",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "NYX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "NYC",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "NYK",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "NYH",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -117.636,
          33.817733,
          3106
        ]
      },
      "properties": {
        "id": "KSOX",
        "code": "KSOX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -75.679861,
          43.755694,
          1960
        ]
      },
      "properties": {
        "id": "KTYX",
        "code": "KTYX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -66.078167,
          18.115667,
          2958
        ]
      },
      "properties": {
        "id": "TJUA",
        "code": "TJUA",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -112.16305,
          33.421111,
          1089
        ]
      },
      "properties": {
        "id": "TPHX",
        "code": "TPHX",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -100.760556,
          46.770833,
          1755
        ]
      },
      "properties": {
        "id": "KBIS",
        "code": "KBIS",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -108.606806,
          45.853778,
          3703
        ]
      },
      "properties": {
        "id": "KBLX",
        "code": "KBLX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -75.440117,
          38.825767,
          164
        ]
      },
      "properties": {
        "id": "KDOX",
        "code": "KDOX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -85.544889,
          42.893889,
          875
        ]
      },
      "properties": {
        "id": "KGRR",
        "code": "KGRR",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -111.669917,
          33.289233,
          1426
        ]
      },
      "properties": {
        "id": "KIWA",
        "code": "KIWA",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -88.084444,
          41.604444,
          760
        ]
      },
      "properties": {
        "id": "KLOT",
        "code": "KLOT",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -113.986222,
          47.041,
          7978
        ]
      },
      "properties": {
        "id": "KMSX",
        "code": "KMSX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "NXB",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "NXG",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "NXC",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "NXK",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "NXH",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -122.965,
          45.715039,
          1728
        ]
      },
      "properties": {
        "id": "KRTX",
        "code": "KRTX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -82.401778,
          27.7055,
          122
        ]
      },
      "properties": {
        "id": "KTBW",
        "code": "KTBW",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -83.515,
          42.111111,
          771
        ]
      },
      "properties": {
        "id": "TDTW",
        "code": "TDTW",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -88.046111,
          42.818889,
          932
        ]
      },
      "properties": {
        "id": "TMKE",
        "code": "TMKE",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -89.993056,
          34.896111,
          482
        ]
      },
      "properties": {
        "id": "TMEM",
        "code": "TMEM",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -95.826944,
          36.071111,
          823
        ]
      },
      "properties": {
        "id": "TTUL",
        "code": "TTUL",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -80.412667,
          25.611083,
          111
        ]
      },
      "properties": {
        "id": "KAMX",
        "code": "KAMX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -121.631611,
          39.495639,
          221
        ]
      },
      "properties": {
        "id": "KBBX",
        "code": "KBBX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -84.56595,
          33.36355,
          972
        ]
      },
      "properties": {
        "id": "KFFC",
        "code": "KFFC",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -97.382944,
          30.721833,
          603
        ]
      },
      "properties": {
        "id": "KGRK",
        "code": "KGRK",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -88.329194,
          33.896917,
          590
        ]
      },
      "properties": {
        "id": "KGWX",
        "code": "KGWX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -87.285583,
          36.736972,
          613
        ]
      },
      "properties": {
        "id": "KHPX",
        "code": "KHPX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -81.7019,
          30.484633,
          160
        ]
      },
      "properties": {
        "id": "KJAX",
        "code": "KJAX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -96.366819,
          41.320369,
          1262
        ]
      },
      "properties": {
        "id": "KOAX",
        "code": "KOAX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -82.715,
          40.006111,
          1148
        ]
      },
      "properties": {
        "id": "TCMH",
        "code": "TCMH",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -84.58,
          38.898056,
          1053
        ]
      },
      "properties": {
        "id": "TCVG",
        "code": "TCVG",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -115.00694,
          36.143889,
          2057
        ]
      },
      "properties": {
        "id": "TLAS",
        "code": "TLAS",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -66.178889,
          18.473889,
          157
        ]
      },
      "properties": {
        "id": "TSJU",
        "code": "TSJU",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -101.709278,
          35.233333,
          3703
        ]
      },
      "properties": {
        "id": "KAMA",
        "code": "KAMA",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -86.770167,
          33.172417,
          759
        ]
      },
      "properties": {
        "id": "KBMX",
        "code": "KBMX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "NZB",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "NZG",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "NZX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "NZC",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "NZK",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "NZH",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -74.064083,
          42.586556,
          1935
        ]
      },
      "properties": {
        "id": "KENX",
        "code": "KENX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -85.459389,
          31.460556,
          537
        ]
      },
      "properties": {
        "id": "KEOX",
        "code": "KEOX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -103.618889,
          34.634167,
          4698
        ]
      },
      "properties": {
        "id": "KFDX",
        "code": "KFDX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -111.198444,
          34.574333,
          7514
        ]
      },
      "properties": {
        "id": "KFSX",
        "code": "KFSX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "NXB",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "NXG",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "NXC",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "NXK",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "NXH",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -108.213761,
          39.062169,
          10101
        ]
      },
      "properties": {
        "id": "KGJX",
        "code": "KGJX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "NYB",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "NYG",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "NYX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "NYC",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "NYK",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "NYH",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -104.18135,
          38.45955,
          5363
        ]
      },
      "properties": {
        "id": "KPUX",
        "code": "KPUX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -111.385333,
          47.459583,
          3805
        ]
      },
      "properties": {
        "id": "KTFX",
        "code": "KTFX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -82.008056,
          41.29,
          932
        ]
      },
      "properties": {
        "id": "TLVE",
        "code": "TLVE",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -80.486111,
          40.501111,
          1385
        ]
      },
      "properties": {
        "id": "TPIT",
        "code": "TPIT",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -76.961944,
          38.758889,
          344
        ]
      },
      "properties": {
        "id": "TDCA",
        "code": "TDCA",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -84.719533,
          44.90635,
          1561
        ]
      },
      "properties": {
        "id": "KAPX",
        "code": "KAPX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -98.028611,
          29.704056,
          767
        ]
      },
      "properties": {
        "id": "KEWX",
        "code": "KEWX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -86.5625,
          36.247222,
          676
        ]
      },
      "properties": {
        "id": "KOHX",
        "code": "KOHX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -78.48975,
          35.665519,
          462
        ]
      },
      "properties": {
        "id": "KRAX",
        "code": "KRAX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "NYB",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "NYG",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "NYX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "NYC",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "NYK",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "NYH",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -93.84125,
          32.450833,
          387
        ]
      },
      "properties": {
        "id": "KSHV",
        "code": "KSHV",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "NZB",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "NZG",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "NZX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "NZC",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "NZK",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "NZH",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -165.295,
          64.511389,
          90
        ]
      },
      "properties": {
        "id": "PAEC",
        "code": "PAEC",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -76.845,
          38.695,
          344
        ]
      },
      "properties": {
        "id": "TADW",
        "code": "TADW",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -87.858056,
          41.796944,
          745
        ]
      },
      "properties": {
        "id": "TORD",
        "code": "TORD",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -94.741944,
          39.498056,
          1089
        ]
      },
      "properties": {
        "id": "TMCI",
        "code": "TMCI",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -75.068889,
          39.948889,
          154
        ]
      },
      "properties": {
        "id": "TPHL",
        "code": "TPHL",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -78.003722,
          40.923167,
          2486
        ]
      },
      "properties": {
        "id": "KCCX",
        "code": "KCCX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -122.495694,
          48.194611,
          642
        ]
      },
      "properties": {
        "id": "KATX",
        "code": "KATX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -83.471667,
          42.7,
          1216
        ]
      },
      "properties": {
        "id": "KDTX",
        "code": "KDTX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -110.63025,
          31.89365,
          5319
        ]
      },
      "properties": {
        "id": "KEMX",
        "code": "KEMX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -95.078733,
          29.4719,
          115
        ]
      },
      "properties": {
        "id": "KHGX",
        "code": "KHGX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -90.682778,
          38.698611,
          722
        ]
      },
      "properties": {
        "id": "KLSX",
        "code": "KLSX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -121.898444,
          37.155222,
          3550
        ]
      },
      "properties": {
        "id": "KMUX",
        "code": "KMUX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "NYB",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "NYG",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "NYX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "NYC",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "NYK",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "NYH",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -146.303447,
          59.460767,
          132
        ]
      },
      "properties": {
        "id": "PAIH",
        "code": "PAIH",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -155.777778,
          20.125278,
          3966
        ]
      },
      "properties": {
        "id": "PHKM",
        "code": "PHKM",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -81.118278,
          33.948722,
          345
        ]
      },
      "properties": {
        "id": "KCAE",
        "code": "KCAE",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -106.624694,
          48.206361,
          2384
        ]
      },
      "properties": {
        "id": "KGGW",
        "code": "KGGW",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -93.215889,
          30.125306,
          137
        ]
      },
      "properties": {
        "id": "KLCH",
        "code": "KLCH",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -117.0418,
          32.919017,
          1052
        ]
      },
      "properties": {
        "id": "KNKX",
        "code": "KNKX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -88.771944,
          37.068333,
          506
        ]
      },
      "properties": {
        "id": "KPAH",
        "code": "KPAH",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "NZB",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "NZG",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "NZX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "NZC",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "NZK",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "NZH",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -81.722778,
          38.311111,
          1213
        ]
      },
      "properties": {
        "id": "KRLX",
        "code": "KRLX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -112.686133,
          43.1056,
          4539
        ]
      },
      "properties": {
        "id": "KSFX",
        "code": "KSFX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -156.629444,
          58.679444,
          144
        ]
      },
      "properties": {
        "id": "PAKC",
        "code": "PAKC",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -87.724528,
          38.26025,
          625
        ]
      },
      "properties": {
        "id": "KVWX",
        "code": "KVWX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -90.488889,
          38.805,
          646
        ]
      },
      "properties": {
        "id": "TSTL",
        "code": "TSTL",
        "secondary": true,
        "products": [
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -80.273056,
          26.688056,
          135
        ]
      },
      "properties": {
        "id": "TPBI",
        "code": "TPBI",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -76.63,
          39.09,
          299
        ]
      },
      "properties": {
        "id": "TBWI",
        "code": "TBWI",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -100.280333,
          29.273139,
          1196
        ]
      },
      "properties": {
        "id": "KDFX",
        "code": "KDFX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -98.976667,
          34.362194,
          1315
        ]
      },
      "properties": {
        "id": "KFDR",
        "code": "KFDR",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -101.814167,
          33.654139,
          3378
        ]
      },
      "properties": {
        "id": "KLBB",
        "code": "KLBB",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -80.217967,
          40.531717,
          1266
        ]
      },
      "properties": {
        "id": "KPBZ",
        "code": "KPBZ",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -102.83,
          44.124722,
          3195
        ]
      },
      "properties": {
        "id": "KUDX",
        "code": "KUDX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -146.303447,
          59.460767,
          132
        ]
      },
      "properties": {
        "id": "PAIH",
        "code": "PAIH",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -88.550667,
          42.9679,
          1023
        ]
      },
      "properties": {
        "id": "KMKX",
        "code": "KMKX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -151.351467,
          60.725914,
          356
        ]
      },
      "properties": {
        "id": "PAHG",
        "code": "PAHG",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -83.82145,
          39.420483,
          1170
        ]
      },
      "properties": {
        "id": "KILN",
        "code": "KILN",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -84.261944,
          33.646944,
          1076
        ]
      },
      "properties": {
        "id": "TATL",
        "code": "TATL",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -80.273969,
          37.0244,
          2965
        ]
      },
      "properties": {
        "id": "KFCX",
        "code": "KFCX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -102.18925,
          31.943461,
          2962
        ]
      },
      "properties": {
        "id": "KMAF",
        "code": "KMAF",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -80.343889,
          26.143056,
          121
        ]
      },
      "properties": {
        "id": "TFLL",
        "code": "TFLL",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -89.336792,
          40.1505,
          731
        ]
      },
      "properties": {
        "id": "KILX",
        "code": "KILX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -119.17875,
          34.412017,
          2807
        ]
      },
      "properties": {
        "id": "KVTX",
        "code": "KVTX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -100.576222,
          41.957944,
          3113
        ]
      },
      "properties": {
        "id": "KLNX",
        "code": "KLNX",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -86.436111,
          39.636944,
          846
        ]
      },
      "properties": {
        "id": "TIDS",
        "code": "TIDS",
        "secondary": true,
        "products": [
          "NST",
          "TZ0",
          "TZ1",
          "TZ2",
          "TV0",
          "TV1",
          "TV2",
          "TZL"
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -97.418967,
          25.916,
          88
        ]
      },
      "properties": {
        "id": "KBRO",
        "code": "KBRO",
        "secondary": false,
        "products": [
          "NSW",
          "DHR",
          "N0S",
          "N1S",
          "N2S",
          "N3S",
          "NST",
          "DVL",
          "EET",
          "N0B",
          "NAB",
          "N1B",
          "NBB",
          "N2B",
          "N3B",
          "N0G",
          "NAG",
          "N1G",
          "NBU",
          "N2U",
          "N3U",
          "NXX",
          "N0X",
          "NAX",
          "N1X",
          "NBX",
          "N2X",
          "N3X",
          "N0C",
          "NAC",
          "N1C",
          "NBC",
          "N2C",
          "N3C",
          "N0K",
          "NAK",
          "N1K",
          "NBK",
          "N2K",
          "N3K",
          "N0H",
          "NAH",
          "N1H",
          "NBH",
          "N2H",
          "N3H",
          "DAA",
          "DTA"
        ]
      }
    }
  ]
})